
<div class="page-not-found container">

  <div *ngIf="megu">
    <br><br><br>
    <h1>
      Cargando contenido, espere ....
    </h1>
  </div>

 <div *ngIf="!megu">
  <h1>404</h1>
  <h2><small>{{"404.page-not-found" | translate}}</small></h2>
  <br/>
  <p>{{"404.help" | translate}}, Dossier</p>
  <br/>
  <p class="text-center">
    <a routerLink="/home" class="btn btn-primary">{{"404.link.home-page" | translate}}</a>
  </p>
 </div>
</div>
