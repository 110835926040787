  <link href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css" rel="stylesheet">
  <link rel="stylesheet" href="https://cdn.unimagdalena.edu.co/code/css/public.min.css" type="text/css">
  <link href="https://cdn.unimagdalena.edu.co/code/css/public_768.min.css" rel="stylesheet" media="(min-width: 768px)">
  <link href="https://cdn.unimagdalena.edu.co/code/css/public_992.min.css" rel="stylesheet" media="(min-width: 992px)">
  <link href="https://cdn.unimagdalena.edu.co/code/css/public_1200.min.css" rel="stylesheet" media="(min-width: 1200px)">
  <link href="https://cdn.unimagdalena.edu.co/code/css/public_1600.min.css" rel="stylesheet" media="(min-width: 1600px)">
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700|Roboto:400,700" rel="stylesheet">
<footer  class="footerUM">
  <div  class="container" style="display: none; visibility: hidden;">
      <div  class="logosGeneral">
          <img  id="selloUnimagdalena" src="https://cdn.unimagdalena.edu.co/images/escudo/bg_dark/default.png" alt="escudo de la Universidad del Magdalena">
    <img  id="selloAcreditacion" src="https://cdn.unimagdalena.edu.co/images/acreditacion2/default.png" alt="Marca de acreditación de alta calidad">
          <img  id="selloColombia" src="https://cdn.unimagdalena.edu.co/images/escudo_colombia.png" alt="Escudo de colombia">
          <img  id="sellosCalidad" src="https://cdn.unimagdalena.edu.co/images/calidad/bg-dark/default.png" alt="Sellos de calidad" class="img-responsive">
      </div>
  </div>
  <div  class="container" style="display: none; visibility: hidden;">
      <div  id="enlacesInteres">
          <div  class="row">
              <div  class="col-xs-12 col-md-3 col-sm-6 footerColum">
                  <h3  class="tituloColum">ENLACES DE INTERÉS</h3>
                  <ul >
                      <li >
                          <a  href="http://estrategia.gobiernoenlinea.gov.co" target="_blank" rel="noopener noreferrer">Gobierno en línea</a>
                      </li>
                      <li >
                          <a  href="http://www.mineducacion.gov.co/1759/w3-channel.html" target="_blank" rel="noopener noreferrer">Ministerio de Educación</a>
                      </li>
                      <li >
                          <a  href="https://www.unimagdalena.edu.co/Publico/Mecanismos">Mecanismos de control y vigilancia</a>
                      </li>
                      <li >
                          <a  href="http://aprende.colombiaaprende.edu.co/estudiantes2016" target="_blank" rel="noopener noreferrer">Colombia Aprende</a>
                      </li>
                      <li >
                          <a  href="https://portal.icetex.gov.co/Portal/" target="_blank" rel="noopener noreferrer">Icetex</a>
                      </li>
                      <li >
                          <a  href="http://www.colciencias.gov.co" target="_blank" rel="noopener noreferrer">Colciencias</a>
                      </li>
                      <li >
                          <a  href="http://www.renata.edu.co/index.php" target="_blank" rel="noopener noreferrer">Renata</a>
                      </li>
                      <li >
                          <a  href="http://www.universia.net.co" target="_blank" rel="noopener noreferrer">Universia</a>
                      </li>
                      <li >
                          <a  href="https://www.encuestafacil.com/universia/UnivGenerica.aspx" target="_blank" rel="noopener noreferrer">universia.encuestafacil </a>
                      </li>
                  </ul>
              </div>
              <div  class="col-xs-12 col-md-3 col-sm-6 footerColum">
                  <h3  class="tituloColum">ATENCIÓN AL CIUDADANO</h3>
                  <ul >
                      <li >
                          <a  role="menuitem" href="https://www.unimagdalena.edu.co/Transparencia">Transparencia y acceso a la información pública</a>
                      </li>
                      <li >
                          <a  role="menuitem" href="https://pse.unimagdalena.edu.co/">Pagos en línea</a>
                      </li>
                      <li >
                          <a  role="menuitem" href="https://www.unimagdalena.edu.co/Publico/PortalNinos">Portal para niños</a>
                      </li>
                      <li >
                          <a  role="menuitem" href="https://www.unimagdalena.edu.co/Publico/Contacto">Ubicación y medios de contacto</a>
                      </li>
                      <li >
                          <a  role="menuitem" href="https://www.unimagdalena.edu.co/Publico/PreguntasFrecuentes">Preguntas frecuentes</a>
                      </li>
                      <li >
                          <a  role="menuitem" href="http://cogui.unimagdalena.edu.co/index.php?option=com_samco&amp;view=pqr&amp;Itemid=867" target="_blank" rel="noopener noreferrer">Peticiones, quejas, reclamos y sugerencias</a>
                      </li>
                      <li >
                          <a  role="menuitem" href="https://www.unimagdalena.edu.co/Publico/ProteccionDatosPersonales">Protección de datos personales</a>
                      </li>
                      <li >
                          <a  role="menuitem" href="https://www.unimagdalena.edu.co/Content/DocumentosSubItems/subitem-20171129151642_181.pdf">Carta de trato digno al ciudadano</a>
                      </li>
              
                      <li >
                          <a  role="menuitem" href="https://www.unimagdalena.edu.co/Publico/Glosario">Glosario</a>
                      </li>
                  </ul>

              </div>
              <div  class="col-xs-12 col-md-3 col-sm-6 footerColum">
                  <h3  class="tituloColum">INFORMACIÓN GENERAL</h3>
                  <ul >
                      <li >
                          <a  href="https://www.unimagdalena.edu.co/Content/Public/Docs/reglamento_estudiantil.pdf" target="_blank" rel="noopener noreferrer">Reglamento estudiantil</a>
                      </li>
                      <li >
                          <a  href="https://admisiones.unimagdalena.edu.co/eventos/index.jsp" target="_blank" rel="noopener noreferrer">Calendario académico</a>
                      </li>
                      <li >
                          <a  href="https://www.unimagdalena.edu.co/Publico/ProteccionDatosPersonales">Protección de datos personales</a>
                      </li>
                      <li >
                          <a  href="https://www.unimagdalena.edu.co/Publico/InformesGestion">Informes de gestión</a>
                      </li>
                  </ul>
              </div>
              <div  class="col-xs-12 col-md-3 col-sm-6 footerColum">
                  <h3  class="tituloColum">SERVICIOS</h3>
                  <ul >
                      <li >
                          <a  href="http://bienestar.unimagdalena.edu.co" target="_blank" rel="noopener noreferrer">Bienestar universitario</a>
                      </li>
                      <li >
                          <a  href="https://www.unimagdalena.edu.co/UnidadesOrganizativas/Dependencia/9">Recursos educativos</a>
                      </li>
                      <li >
                          <a  href="https://www.unimagdalena.edu.co/UnidadesOrganizativas/Dependencia/6">Biblioteca Germán Bula Meyer</a>
                      </li>
                      <li >
                          <a  href="http://consultorio.unimagdalena.edu.co" target="_blank" rel="noopener noreferrer">Consultorio jurídico y centro de conciliación</a>
                      </li>
                      <li >
                          <a  href="https://www.unimagdalena.edu.co/UnidadesOrganizativas/Dependencia/4">Cartera</a>
                      </li>
                      <li >
                          <a  href="https://pse.unimagdalena.edu.co">Pagos en línea</a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
<div id="footer-bottom">
        <div class="container text-center">

            <div class="row">
                <div class="col-12 col-xs-12 text-center">
                    <h3 class="tituloColum">Información de contacto</h3>
                    <ul>
                        <li>Línea Gratuita Nacional: 01 8000 180 504. PBX: (57 - 5) 4381000 - 4365000</li>
                        <li><a href="https://goo.gl/maps/tad2rQS5Jqj" target="_blank" rel="noopener noreferrer">Dirección: Carrera 32 No 22 – 08 Santa Marta D.T.C.H. - Colombia. Código Postal No. 470004</a></li>
                        <li><a href="mailto:contacto@unimagdalena.edu.co" target="_blank" rel="noopener noreferrer">Correo electrónico: ciudadano@unimagdalena.edu.co</a></li>
                    </ul>

                </div>
                <div class="col-12 col-xs-12 text-center">
                    <p class="infoContacto">La Universidad del Magdalena está sujeta a inspección y vigilancia por el Ministerio de Educación Nacional.</p>
                    <p>Desarrollado por el Centro de Investigación y Desarrollo de Software CIDS - Unimagdalena © 2023</p><p>
                        <a href="#goto-content" id="goto-up" class="sr-only">Ir al inicio de la página</a>
                </p></div>
            </div>
        </div>
    </div>

</footer>

<footer class="text-lg-start" style="display: none; visibility: hidden;">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <div class=" container p-4">
      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Lorem ipsum</a>
            </li>
            <li>
              <a routerLink="./" class="">Ut facilisis</a>
            </li>
            <li>
              <a routerLink="./" class="">Aenean sit</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Suspendisse potenti</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
            molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae aliquam
            voluptatem veniam, est atque cumque eum delectus sint!
          </p>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="bottom-footer p-1 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <p class="m-0">
        <a class="text-white"
           href="http://www.dspace.org/">{{ 'footer.link.dspace' | translate}}</a>
        {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        <a class="text-white"
           href="https://www.lyrasis.org/">{{ 'footer.link.lyrasis' | translate}}</a>
      </p>
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
          <a class="text-white" href="javascript:void(0);"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Copyright -->
</footer>
