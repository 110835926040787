<ds-loading *ngIf="(loading | async) || (isAuthenticated | async)" class="m-5"></ds-loading>
<div *ngIf="!(loading | async) && !(isAuthenticated | async)" class="px-4 py-3 login-container">
  <ng-container *ngFor="let authMethod of (authMethods | async); let i = index">
    <div *ngIf="i === 1" class="text-center mt-2">
      <span class="align-middle">{{"login.form.or-divider" | translate}}</span>
    </div>
    <ds-log-in-container [authMethod]="authMethod" [isStandalonePage]="isStandalonePage"></ds-log-in-container>
  </ng-container>

  <div class="dropdown-divider"></div>
  <a class="dropdown-item" *ngIf="canRegister$ | async" [routerLink]="[getRegisterRoute()]" style="display: none; visibility: hidden;">{{"login.form.new-user" | translate}}</a>
  <a class="dropdown-item" [routerLink]="[getForgotRoute()]">{{"login.form.forgot-password" | translate}}</a>
</div>
